<template>
  <div class="area head-user space-between">
    <div class="user-content flex-start">
      <div class="infor">
        <div class="hello">{{ user.name }}，您好，欢迎进入系统管理！</div>
        <div class="weather">请访问需要处理的管理模块</div>
      </div>
    </div>
    <div class="user-job flex-start">
      <div class="min-area">
        <div class="title">
          <i class="el-icon-time IconBule"></i>待审核申报单位
        </div>
        <div class="number">{{ counts.needApproved }}</div>
      </div>
      <div class="min-area">
        <div class="title">
          <i class="el-icon-message IconOrange"></i>申报单位总计
        </div>
        <div class="number">{{ counts.applyUnitTotal }}</div>
      </div>

      <div class="min-area">
        <div class="title">
          <i class="el-icon-data-line IconGray"></i>评审机构
        </div>
        <div class="number">{{ counts.reviewOrg }}</div>
      </div>

      <div class="min-area">
        <div class="title">
          <i class="el-icon-user IconTurquoise"></i>专家数量
        </div>
        <div class="number">{{ counts.expert }}</div>
      </div>
    </div>
  </div>

  <el-row :gutter="20">
    <el-col :span="18">
      <div class="area">
        <div class="sort-title">
          <div class="title">
            <i class="el-icon-time IconBule"></i>
            <span class="TitleWords">待审核申报单位</span>
          </div>
          <div class="more">
            <router-link to="/SystemManage/User/ApplyUnit?isApproved=false"
              >更多</router-link
            >
          </div>
        </div>
      </div>
    </el-col>
    <el-col :span="6">
      <div class="area">
        <div class="sort-title">
          <div class="title">
            <i class="el-icon-magic-stick IconGreen"></i>
            <span class="TitleWords">快捷操作</span>
          </div>
        </div>
        <div class="shortcuts flex-start">
          <div class="box">
            <router-link to="/work/add">
              <i class="el-icon-edit-outline Red"></i>
              <span class="title">添加用户</span>
            </router-link>
          </div>

          <div class="box">
            <router-link to="/account/setting">
              <i class="el-icon-s-operation Turquoise"></i>
              <span class="title">设置</span>
            </router-link>
          </div>
        </div>
      </div>
      <!--
        <div class="area">
          <div class="sort-title">
            <div class="title">
              <i class="el-icon-tickets IconRed"></i>
              <span class="TitleWords">系统公告</span>
            </div>
            <div class="more">
              <el-link type="primary" :underline="false" href="/#/Ordinary/OrdinaryInformation">更多</el-link>
            </div>
          </div>
          <div class="index-notice">
            <div class="list" v-for="notice in Announcement">
              <div class="title">
                <span
                  @click="ShowNotice(notice.AnnouncementTitle,notice.AnnouncementContent,notice.MessageDate)"
                  class="LinkBlack"
                >{{ notice.AnnouncementTitle }}</span>
              </div>
              <div class="time">{{ notice.MessageDate }}</div>
            </div>
          </div>
        </div>
        -->
    </el-col>
  </el-row>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";

export default defineComponent({
  setup() {
    const user = inject("user");
    return {
      user,
      counts: {
        needApproved: 0,
        applyUnitTotal: 0,
        reviewOrg: 0,
        expert: 0,
      },
    };
  },
});
</script>

<style scoped>
</style>