
import { defineComponent, inject } from "vue";

export default defineComponent({
  setup() {
    const user = inject("user");
    return {
      user,
      counts: {
        needApproved: 0,
        applyUnitTotal: 0,
        reviewOrg: 0,
        expert: 0,
      },
    };
  },
});
